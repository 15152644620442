import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'privacy-policy',
        path: `privacy-policy`,
        component: React.lazy(() => import('views/legal/privacy-policy')),
    },
    {
        key: 'terms-use',
        path: `terms-use`,
        component: React.lazy(() => import('views/legal/terms-use')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component:  React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'teams',
        path: `${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key: 'whitelabel',
        path: `${APP_PREFIX_PATH}/whitelabel`,
        component: React.lazy(() => import('views/app-views/whitelabel'))
    },
    {
        key: 'whitelabelusers',
        path: `${APP_PREFIX_PATH}/whitelabel/users`,
        component: React.lazy(() => import('views/app-views/whitelabel/users'))
    },
    {
        key: 'bots',
        path: `${APP_PREFIX_PATH}/bots`,
        component: React.lazy(() => import('views/app-views/bots'))
    },
    {
        key: 'bots-add',
        path: `${APP_PREFIX_PATH}/bot/add`,
        component: React.lazy(() => import('views/app-views/bots/add'))
    },
    {
        key: 'bots-edit',
        path: `${APP_PREFIX_PATH}/bot/edit`,
        component: React.lazy(() => import('views/app-views/bots/edit'))
    },
    {
        key: 'integartions',
        path: `${APP_PREFIX_PATH}/integration`,
        component: React.lazy(() => import('views/app-views/integrations'))
    },
    {
        key: 'fb-auth',
        path: `${APP_PREFIX_PATH}/fb-auth`,
        component: React.lazy(() => import('views/app-views/fbAuth'))
    },
    {
        key: 'comments-bot',
        path: `${APP_PREFIX_PATH}/comments-bot`,
        component: React.lazy(() => import('views/app-views/comments-bot'))
    },
    {
        key: 'comments-bot-list',
        path: `${APP_PREFIX_PATH}/comments-bot-list`,
        component: React.lazy(() => import('views/app-views/comments-bot/list'))
    },
    {
        key: 'comments-bot-edit',
        path: `${APP_PREFIX_PATH}/comments-bot-edit`,
        component: React.lazy(() => import('views/app-views/comments-bot/edit'))
    },
    {
        key: 'insta-post',
        path: `${APP_PREFIX_PATH}/instagram-post`,
        component: React.lazy(() => import('views/app-views/post/instagram'))
    },
    {
        key: 'fb-post',
        path: `${APP_PREFIX_PATH}/facebook-post`,
        component: React.lazy(() => import('views/app-views/post/facebook'))
    },
    {
        key: 'post-list',
        path: `${APP_PREFIX_PATH}/post-list`,
        component: React.lazy(() => import('views/app-views/post/list'))

    },
    {
        key: 'leads',
        path: `${APP_PREFIX_PATH}/leads`,
        component: React.lazy(() => import('views/app-views/leads'))

    },
    {
        key: 'bonuses',
        path: `${APP_PREFIX_PATH}/bonuses`,
        component: React.lazy(() => import('views/app-views/bonuses'))

    },
    {
        key: 'tools',
        path: `${APP_PREFIX_PATH}/tools`,
        component: React.lazy(() => import('views/app-views/tools'))

    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/plugin-setting`,
        component: React.lazy(() => import('views/app-views/tools/setting'))

    }

]